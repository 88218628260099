import React, { useContext, useEffect, useState } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
} from "mdb-react-ui-kit";
import { profileApi } from "../../Components/services/profile.service";
import { useAuthHeaders } from "../../Components/Token";
import { useNavigate } from "react-router-dom";
import userContext from "../../Components/UseContext/UseContext";

function Profile() {
  const headers = useAuthHeaders();
  const [profileData, setProfileData] = useState();
  const navigate = useNavigate();
  const context = useContext(userContext);

  useEffect(() => {
    const fetchProfileData = async () => {
      const tokenId = localStorage.getItem("tokenId");

      if (!tokenId) {
        navigate("/login");
        return;
      }

      try {
        const data = await profileApi(headers);
        setProfileData(data.user);
        context.setProfileNumber(data.user.mobile);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    if (headers) {
      fetchProfileData();
    }
  }, [headers, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("tokenId");
    window.location.reload(false);
  };

  return (
    <div className="gradient-custom-2" style={{ marginTop: "2rem" }}>
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol lg="9" xl="7">
            <MDBCard>
              <div
                className="rounded-top text-white d-flex flex-row"
                style={{ backgroundColor: "#E9FAC6", height: "200px" }}
              >
                <div
                  className="ms-4 mt-5 d-flex flex-column"
                  style={{ width: "150px" }}
                >
                  <MDBCardImage
                    src="https://loverary.files.wordpress.com/2013/10/facebook-default-no-profile-pic.jpg?w=1200"
                    alt="Generic placeholder image"
                    className="mt-4 mb-2 img-thumbnail"
                    fluid
                    style={{ width: "150px", zIndex: "1" }}
                  />
                </div>
                <div className="ms-3" style={{ marginTop: "130px" }}>
                  <MDBTypography tag="h5">
                    {profileData ? (
                      <h3 className="text-dark">{profileData.role}</h3>
                    ) : (
                      <p>Loading...</p>
                    )}
                  </MDBTypography>
                </div>
              </div>

              <MDBCardBody className="text-black p-4">
                <div>
                  <p className="lead fw-normal mb-1">About</p>
                  <div className="p-4" style={{ backgroundColor: "#f8f9fa" }}>
                    {profileData ? (
                      <>
                        <MDBCardText className="font-italic mb-1">
                          {profileData.name}
                        </MDBCardText>

                        <MDBCardText className="font-italic mb-1">
                          {profileData.mobile}
                        </MDBCardText>

                        <MDBCardText className="font-italic mb-1">
                          {profileData.email}
                        </MDBCardText>
                      </>
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>
                </div>
                <button className="btn btn-dark my-4" onClick={handleLogout}>
                  LogOut
                </button>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default Profile;
