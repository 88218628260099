import React from "react";
import "../checkOut/checkOut.scss";

function Billing({
  setFirstName,
  setLastName,
  setAddress,
  setCountery,
  setState,
  setPinCode,
  setPhone,
  setEmail,
  email,
  phone,
  firstName
}) {
  return (
    <div className="col-lg-6 billing-inner">
      <h4>Billing details</h4>
      <div>
        <div className="d-flex justify-content-between py-4">
          <input
            type="text"
            name="name"
            id="name"
            placeholder="First name"
            className="input"
            style={{ marginRight: "10px" }}
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Last name"
            className="input"
            required
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <strong>Street address</strong>
        <input
          type="text"
          name="address"
          id="address"
          placeholder="House number and street name"
          className="my-2 input"
          required
          onChange={(e) => setAddress(e.target.value)}
        />
        <input
          type="text input"
          name="apartment"
          id="apartment"
          placeholder="Apartment, suite, unit, etc. (optional)"
          className="my-2 input"
          required
          onChange={(e) => setAddress(e.target.value)}
        />
        <p className="m-0">
          <label htmlFor="billing_country">Town / City</label>
          <input
            type="text"
            name="countery"
            id="Countery"
            placeholder="Countery"
            className="my-2 input"
            required
            onChange={(e) => setCountery(e.target.value)}
          />
        </p>
        <p className="py-2 m-0">
          <label htmlFor="billing_state">State</label>
          <input
            type="text"
            name="State"
            id="State"
            placeholder="State"
            className="my-2 input"
            required
            onChange={(e) => setState(e.target.value)}
          />
        </p>
        <p className="m-0">
          <label htmlFor="billing_pincode">PIN Code</label>
          <input
            type="text"
            name="pincode"
            id="pincode"
            className="my-2 input"
            required
            onChange={(e) => setPinCode(e.target.value)}
          />
        </p>
        <p className="py-2 m-0">
          <label htmlFor="billing_phone">Phone</label>
          <input
            type="text"
            name="number"
            id="number"
            className="my-2 input"
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </p>
        <p className="m-0">
          <label htmlFor="billing_email_address">Email address</label>
          <input
            type="text"
            name="email"
            id="email"
            className="my-2 input"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </p>
      </div>
    </div>
  );
}

export default Billing;
