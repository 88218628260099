import React, { useEffect, useState } from "react";
import "../Offers/offers.scss";
import offerChair from "../../assets/images/offers_chair.png";
import { Link } from "react-router-dom";
import { IoMdStar } from "react-icons/io";
import { FaShoppingBasket } from "react-icons/fa";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import { API_BASE_URL } from "../../utils/use";
import EnquiryButton from "../Cards/EnquiryButton";

function Offers({ categoryName }) {
  const [arrival, setArrival] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (categoryName) {
      axios
        .get(
          `${API_BASE_URL}/user/product/getnewarrivalproducts?categoryname=${categoryName}`
        )
        .then((res) => {
          setArrival(res.data.products);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [categoryName]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
  };

  return (
    <div className="container offers-container">
      <Slider {...settings}>
        {arrival.map((chair, index) => (
          <div
            className="offer-products"
            data-aos="fade-up-right"
            data-aos-delay="600"
            data-aos-duration="500"
            key={index}
          >
            <div className="offer-products-images">
              <div className="products-image-container">
                <Link to={`/product/${chair._id}`}>
                  <img
                    src={chair.productimages[0]?.productimage}
                    alt="arrival imaage"
                  />
                </Link>

                <div className="offer-products-background"></div>
              </div>

              <div className="color-container">
                <div className="color-variation">
                  <p className="m-0">Colour</p>

                  <div className="variation">
                    {chair.Productcolor.slice(0, 3).map((color, i) => (
                      <div
                        className={`color ${
                          selectedImage === color ? "active" : ""
                        }`}
                        key={i}
                        style={{ background: `${color}` }}
                        onClick={() => setSelectedImage(color)}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="products-content">
              <span className="arrival"> New Arrival </span>
              <h3 className="chair-type m-0">{chair.categoryname}</h3>

              <div className="reviews py-2">
                <IoMdStar className="start" />
                <IoMdStar className="start" />
                <IoMdStar className="start" />
                <IoMdStar className="start" />
                <IoMdStar className="start" />
                <p className="m-0">( 0 Reviews )</p>
              </div>

              <h6 className="chair-name py-2">{chair.productName}</h6>

              <div className="price">
                <span className="hide-price">₹ {chair.originalprice} </span>
                <span className="current-price mx-2">₹ {chair.saleprice} </span>
              </div>

              <div className="shopping">
                <Link to={`/product/${chair._id}`}>
                  <button className="shopping-button">View</button>
                </Link>

                <EnquiryButton
                  detail={chair}
                  _id={chair._id}
                  type="text_button"
                  selectedImage={selectedImage}
                />
              </div>

              <Link
                to={`/products/${chair.categoryname}`}
                className="see-collections"
              >
                See Collections
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Offers;
