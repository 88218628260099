import axios from "axios";
import { API_BASE_URL } from "../../utils/use";
import { useParams } from "react-router-dom";

export const resetPassWordApi = async (data, reset) => {
  const { password, confirmPassword } = data;

  console.log("reset in apii", reset)

  return await axios.post(`${API_BASE_URL}/user/password/reset/` + reset, {
    newPassword: password,
    confirmPassword: confirmPassword,
  });
};
