import React, { useContext, useEffect, useState } from "react";
import "../../Pages/Home/home.scss";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { AiOutlineHeart } from "react-icons/ai";
import userContext from "../UseContext/UseContext";

function AddCartFixedButton() {
  const [whisCount, setWhisCartCount] = useState(0);
  const temp = useContext(userContext);
  const isLoggedIn = localStorage.getItem("tokenId");
  const wishlistLength = localStorage.getItem("wishlistLength");
  const WhislistTotalCount = temp > 0 ? temp : wishlistLength;

  useEffect(() => {
    setWhisCartCount(WhislistTotalCount);
  }, [WhislistTotalCount]);

  const localWhislistLength = localStorage.getItem("whislistLengthLocal");

  return (
    <div className="add-fixed-button d-flex flex-column">
      <button
        className="my-1"
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="900"
      >
        {isLoggedIn ? (
          <Link to="/wishlist" className="position-relative text-dark">
            <AiOutlineHeart className="fs-4" />
            {whisCount > 0 && (
              <Badge bg="secondary" className="badge rounded-circle">
                {whisCount}
              </Badge>
            )}
          </Link>
        ) : (
          <Link to="/wishlist" className="position-relative text-dark">
            <AiOutlineHeart className="fs-4" />
            {localWhislistLength > 0 && (
              <Badge bg="secondary" className="badge rounded-circle">
                {localWhislistLength}
              </Badge>
            )}
          </Link>
        )}
      </button>
    </div>
  );
}

export default AddCartFixedButton;
