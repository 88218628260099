import React from "react";
import "../Products/products.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Furnitures from "./Furnitures/Furnitures";

function Products({ data, selectCategory }) {
  const selectedCategory = data.find(
    (item) => item.activeKey === selectCategory
  );

  const defaultActiveKey = selectedCategory
    ? selectedCategory.name
    : data[0]?.name;

  return (
    <div className="Products-wrapper py-4">
      <div className="container my-4">
        <center>
          <h3 className="fw-bold">We Loved Trend</h3>

          <Tabs
            defaultActiveKey={defaultActiveKey}
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            {data.map((item, i) => (
              <Tab key={i} eventKey={item.name} title={item.name}>
                <Furnitures name={item.name} />
              </Tab>
            ))}
          </Tabs>
        </center>
      </div>
    </div>
  );
}

export default Products;
