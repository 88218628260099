import React from 'react'
import "../Client/client.scss"
import nova from "../../assets/images/Nova.png"
import mbm from "../../assets/images/MBM.png"
import nazia from "../../assets/images/Nazic.png"
import lamino from "../../assets/images/Lamino.png"
import avan from "../../assets/images/AVAN.png"
import aio from "../../assets/images/AIO.png"

function Client() {
  return (
    <div className='container'>
        <div className="client-logo">
            <img src={nova} alt="nova" />
            <img src={mbm} alt="nova" />
            <img src={nazia} alt="nova" />
            <img src={lamino} alt="nova" />
            <img src={avan} alt="nova" />
            <img src={aio} alt="nova" />
        </div>
    </div>
  )
}

export default Client