import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import "../ResponsiveNavbar/responsivenavbar.scss";
import { AiOutlineHeart, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaShoppingBasket } from "react-icons/fa";

function ResponsiveNavbar() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
  };

  const handleNavItemClicked = () => {
    // Close the Offcanvas when a nav-item is clicked
    setShowOffcanvas(false);
  };

  return (
    <div className="responsive-navbar">
      <button
        onClick={() => setShowOffcanvas(true)}
        className="responsive-nav-button"
      >
        <AiOutlineMenu />
      </button>

      <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>CHENNAI FURNITURE</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav defaultActiveKey="#home" className="flex-column">
            <Link
              to="/"
              active={showOffcanvas}
              onClick={handleNavItemClicked}
              className="responsive-nav-items"
            >
              HOME
            </Link>
            <Link
              to="#about"
              active={showOffcanvas}
              onClick={handleNavItemClicked}
              className="responsive-nav-items"
            >
              CATEGORY
            </Link>
            <Link
              to="#services"
              active={showOffcanvas}
              onClick={handleNavItemClicked}
              className="responsive-nav-items"
            >
              SHOP
            </Link>
            <Link
              to="#services"
              active={showOffcanvas}
              onClick={handleNavItemClicked}
              className="responsive-nav-items"
            >
              BLOG
            </Link>
            <Link
              to="#services"
              active={showOffcanvas}
              onClick={handleNavItemClicked}
              className="responsive-nav-items"
            >
              ABOUT US
            </Link>
            <Link
              to="/contact"
              active={showOffcanvas}
              onClick={handleNavItemClicked}
              className="responsive-nav-items"
            >
              CONTACT US
            </Link>

            <Link
              to="/wishlist"
              active={showOffcanvas}
              onClick={handleNavItemClicked}
              className="responsive-nav-items"
            >
              <AiOutlineHeart className="fs-4" /> Wishlist
            </Link>

            <Link
              to="/profile"
              active={showOffcanvas}
              onClick={handleNavItemClicked}
              className="responsive-nav-items bg-dark text-light"
            >
              Profile
            </Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default ResponsiveNavbar;
