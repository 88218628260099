import axios from "axios";
import { API_BASE_URL } from "../../utils/use";

export const placeOrderApi = async (data) => {
  const {
    headers,
    firstName,
    lastName,
    address,
    countery,
    state,
    pinCode,
    phone,
    email,
    checkOut,
  } = data;

  console.log("subTotal", checkOut.subTotal);
  console.log("shipping", checkOut.shipping);
  console.log("total", checkOut.total);

  return await axios.post(
    `${API_BASE_URL}/user/order/neworder`,
    {
      shippingInfo: {
        firstname: firstName,
        lastname: lastName,
        streetaddress: address,
        // apartmentno: address,
        country: countery,
        state: state,
        phone: phone,
        postalCode: pinCode,
        email: email,

        // shipToDifferentAddress: shipToDifferentAddress,
      },
      orderItems: checkOut.cartItems.map((product) => ({
        productId: product.product._id,
        quantity: product.quantity,
        productSubTotal: product.subTotal,
      })),
      subTotal: checkOut.subTotal,
      shipping: checkOut.shipping,
      orderTotal: checkOut.total,
    },
    {
      headers,
    }
  );
};
