import React, { useContext, useEffect, useState } from "react";
import "../viewAllProducts.scss";
import { getallcategoryApi } from "../../../../Components/services/getallcategory.service";
import userContext from "../../../../Components/UseContext/UseContext";
import { getallfilterApi } from "../../../../Components/services/getallfilter.service";
import axios from "axios";
import { API_BASE_URL } from "../../../../utils/use";

const useDebouncedEffect = (effect, delay, deps) => {
  const [debouncedValue, setDebouncedValue] = useState(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(effect);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, deps);

  return debouncedValue;
};

function Filters() {
  const [allCategory, setAllCategory] = useState([]);
  const [allCategoryLength, setAllCategoryLength] = useState([]);
  const [filter, setFilter] = useState([]);
  const [getCategoriefilter, setGetCategoriefilter] = useState("");
  const [getColorfilter, setGetColorfilter] = useState("");
  const [getAllProductfilter, setGetAllProductfilter] = useState("");
  const [getMaterialfilter, setGetMaterialfilter] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(30000);

  const storeResults = useContext(userContext);

  const handleGetAllProductilter = (data) => {
    setGetAllProductfilter(data);
  };

  const handleGetCategoriefilter = (data) => {
    setGetCategoriefilter(data);
    setGetColorfilter("");
  };

  const handleGetColorfilter = (data) => {
    setGetColorfilter(data);
  };

  const handleGetMaterialfilter = (data) => {
    setGetMaterialfilter(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getallcategoryApi();

        if (response && response.data) {
          setAllCategory(response.data.categoriesWithCount);
          setAllCategoryLength(response.data);
        } else {
          // console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("API call failed", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getallfilterApi();

        if (response && response.data) {
          console.log("filterApi -:", response.data);
          setFilter(response.data.filterstypes);
        } else {
          console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("API call failed", error);
      }
    };

    fetchData();
  }, []);

  useDebouncedEffect(
    () => {
      storeResults.setSearchLoader(true);
      axios
        .get(
          `${API_BASE_URL}/user/poroduct/filterproduct?&category=${getCategoriefilter}&color=${getColorfilter}&material=${getMaterialfilter}`
        )
        .then((response) => {
          console.log("Filtered Products:", response.data.products);
          storeResults.setSearchResults(response.data.products);
          storeResults.setSearchLoader(false);
        })
        .catch((error) => {
          console.error("Error fetching filtered products:", error);
        });
    },
    300,
    [getCategoriefilter, getColorfilter, getMaterialfilter]
  );

  const productColor = (data) => {
    return {
      backgroundColor: data,
    };
  };

  const handleSubmit = () => {
    storeResults.setSearchLoader(true);

    axios
      .get(
        `${API_BASE_URL}/user/poroduct/filterproduct?&category=${getCategoriefilter}&color=${getColorfilter}&material=${getMaterialfilter}&price_min=${minPrice}&price_max=${maxPrice}`
      )
      .then((response) => {
        console.log("Filtered Products:", response.data.products);
        storeResults.setSearchResults(response.data.products);
        storeResults.setSearchLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching filtered products:", error);
      });
  };

  return (
    <>
      <h6 className="fw-bold my-4">Product Categories</h6>
      {/* all  */}
      <div className="filters">
        {/* <div
          className="categorie-filters d-flex justify-content-between my-1"
          tabIndex={0}
          onClick={() => handleGetAllProductilter("All")}
        >
          <h6>All</h6>
          <span>
            (
            {allCategoryLength.products ? allCategoryLength.products.length : 0}
            )
          </span>
        </div> */}

        {allCategory.map((category, index) => {
          return (
            <button
              key={index}
              className="categorie-filters d-flex justify-content-between my-1"
              onClick={() => handleGetCategoriefilter(category.categoryname)}
              tabIndex={0}
            >
              <h6>{category.categoryname}</h6>
              <span>({category.count})</span>
            </button>
          );
        })}
      </div>

      {/* category  */}
      <div className="filters">
        {filter.map((filter, i) => (
          <div key={i}>
            <h6 className="fw-bold my-4">{filter?.filter?.name}</h6>
            <div className="color-filters">
              {filter?.filter_Types?.map((filter_Type, index) => (
                <React.Fragment key={index}>
                  {filter?.filter?.name === "Color" ? (
                    <div
                      className="color"
                      style={productColor(filter_Type)}
                      onClick={() => handleGetColorfilter(filter_Type)}
                      tabIndex={0}
                    ></div>
                  ) : (
                    <div
                      className="text-section m-2"
                      tabIndex={0}
                      onClick={() => handleGetMaterialfilter(filter_Type)}
                    >
                      <p>{filter_Type}</p>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* price  */}
      <h6 className="fw-bold py-3">Price</h6>
      <div className="price-filter">
        <div className="box">
          <div className="range-input">
            <input
              type="range"
              value={minPrice}
              onChange={(e) => setMinPrice(parseInt(e.target.value))}
              min={0}
              max={6000}
              className="m-0"
            />
            <input
              type="range"
              value={maxPrice}
              onChange={(e) => setMaxPrice(parseInt(e.target.value))}
              min={0}
              max={10000}
              className="m-0"
            />
          </div>

          <div className="price-filter">
            <p className="">Price :</p>
            <p className="mx-1  fw-semibold">
              ₹ {minPrice} - ₹ {maxPrice}
            </p>
          </div>

          <button type="submit" className="price-filter-btn" onClick={handleSubmit}>
            submit
          </button>
        </div>
      </div>
    </>
  );
}

export default Filters;
