import React, { useEffect, useState } from "react";
import "../Offers/offers.scss";
import Office_Furniture from "../../assets/images/Office_Furniture.png";
import { Link } from "react-router-dom";
import { IoMdStar } from "react-icons/io";
import { FaShoppingBasket } from "react-icons/fa";
import "../OfficeFurniture/officeFurniture.scss";
import axios from "axios";
import { API_BASE_URL } from "../../utils/use";
import EnquiryButton from "../Cards/EnquiryButton";

function OfficeFurniture({ categoryName }) {
  const OfficeFurniture = [
    {
      image: Office_Furniture,
      arrival: "New Arrival",
      type: "Complete Office Furniture Set",
      name: "Decor For Drowing",
      max_price: "1000.00",
      min_price: "800.00",
    },
    // Add more chair objects as needed
  ];
  const [arrival, setArrival] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  console.log("arrival", arrival);

  useEffect(() => {
    if (categoryName) {
      axios
        .get(
          `${API_BASE_URL}/user/product/getnewarrivalproducts?categoryname=${categoryName}`
        )
        .then((res) => {
          setArrival(res.data.products.slice(0, 1));
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [categoryName]);

  return (
    <div className="office-furniture">
      <div className="container">
        {arrival.map((chair, index) => (
          <div className="offer-products" key={index}>
            <div
              className="products-content"
              // data-aos="fade-right"
              // data-aos-delay="500"
            >
              <span className="arrival"> New Arrival </span>
              <h3 className="chair-type m-0">{chair.categoryname}</h3>
              <div className="reviews py-2">
                <IoMdStar className="start" />
                <IoMdStar className="start" />
                <IoMdStar className="start" />
                <IoMdStar className="start" />
                <IoMdStar className="start" />
                <p className="m-0">( 0 Reviews )</p>
              </div>
              <h6 className="chair-name py-2">{chair.productName}</h6>
              <div className="price">
                <span className="hide-price">₹ {chair.originalprice} </span>
                <span className="current-price mx-2">₹ {chair.saleprice} </span>
              </div>

              <div className="variation">
                {chair.Productcolor.slice(0, 3).map((color, i) => (
                  <div
                    className={`color ${
                      selectedImage === color ? "active" : ""
                    }`}
                    key={i}
                    style={{ background: `${color}` }}
                    onClick={() => setSelectedImage(color)}
                  ></div>
                ))}
              </div>

              <div className="shopping">
                <Link to={`/product/${chair._id}`}>
                  <button className="shopping-button">View</button>
                </Link>

                <EnquiryButton
                  detail={chair}
                  _id={chair._id}
                  type="text_button"
                  selectedImage={selectedImage}
                />
              </div>

              <Link
                to={`/products/${chair.categoryname}`}
                className="see-collections"
              >
                See Collections
              </Link>
            </div>

            <div
              className="offer-furniture-products-images"
              // data-aos="fade-left"
              // data-aos-delay="500"
              // data-aos-duration="500"
            >
              <Link to={`/product/${chair._id}`}>
                <img
                  src={chair.productimages[0]?.productimage}
                  alt="arrivel image"
                />
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="office-furniture-image"></div>
    </div>
  );
}

export default OfficeFurniture;
