import React, { useContext } from "react";
import { IoMdStar } from "react-icons/io";
import { Link } from "react-router-dom";
import WishlistButton from "../../../../Components/Buttons/WishlistButton";
import PreviewButton from "../../../../Components/Buttons/PreviewButton";
import TriangleLoader from "../../../../Components/Loader/TriangleLoader";
import WhatsApp from "../../../../Components/MinoreComponents/WhatsApp";
import userContext from "../../../../Components/UseContext/UseContext";

function ProductHorizontal({ allProducts }) {
  const context = useContext(userContext);
  const loader = context?.searcLoader;

  return (
    <div className="view-all-products-container">
      <div className="products-container">
        {loader === true ? (
          <TriangleLoader />
        ) : (
          <>
            {allProducts.length === 0 ? (
              <h4 className="text-danger my-4">No Products</h4>
            ) : (
              <>
                {allProducts.map((products, i) => (
                  <>
                    <div className="all-products" key={i}>
                      <Link
                        to={`product/${products._id}`}
                        className="text-decoration-none text-dark"
                      >
                        <img
                          src={
                            products.productimages?.[0]?.productimage || null
                          }
                          className="products-image"
                          alt="product1"
                        />
                      </Link>
                      <div className="products-content products-content-two">
                        <h6 className="chair-name py-2">
                          {products.productName}
                        </h6>

                        <div className="d-flex justify-content-between">
                          <div className="price align-items-center">
                            <span className="hide-price">
                              ₹ {products.originalprice}{" "}
                            </span>
                            <span className="current-price mx-2">
                              ₹ {products.saleprice}{" "}
                            </span>
                          </div>

                          <WhatsApp />
                        </div>
                      </div>
                      <div className="sale">SALE</div>

                      <div className="sub-items">
                        <WishlistButton data={products} />
                        <PreviewButton data={products} />
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ProductHorizontal;
