import React from "react";
import "../LatestNews/latestNews.scss";
import LatestNews01 from "../../assets/images/NewsThumbnail01.jpg";
import Card from "react-bootstrap/Card";
import avatar from "../../assets/images/avatar-icon.png"

function LatestNews() {
  const LatestNews = [
    {
      id: 1,
      image: LatestNews01,
      title: "HERE WILL YOUR TITLE",
      details:"Lorem ipsum is simply dummy text of the printing and typese....",
      date: "May 9, 2020",
      profile: avatar
    },
    {
      id: 2,
      image: LatestNews01,
      title: "HERE WILL YOUR TITLE",
      details:"Lorem ipsum is simply dummy text of the printing and typese....",
      date: "May 9, 2020",
      profile: avatar
    },
    {
      id: 3,
      image: LatestNews01,
      title: "HERE WILL YOUR TITLE",
      details:"Lorem ipsum is simply dummy text of the printing and typese....",
      date: "May 9, 2020",
      profile: avatar
    },
  ];

  return (
    <div className="latest-news">
      <div className="container">
        <center className="headering">
          <h3 className="fw-semibold">Latest News</h3>
          <p>Praesent ut ligula non mi curabitur at lacus elit</p>
        </center>   
        <div className="news-cards">
          {LatestNews.map((news, index) => (
            <div className="news" key={index}>
              <Card>
                <div className="card-image">
                  <img src={news.image} /> 
                </div> 
                <Card.Body>
                  <div className="profile">
                    <img src={news.profile} alt="profile" />
                  </div>
                  <h6 className="news-date">Posted {news.date}</h6>
                  <h5 className="py-2">{news.title}</h5>
                  <p className="news-detial"> {news.details} </p>
                </Card.Body>
                <button>READ MORE</button>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LatestNews;