import React from "react";
import { Link } from "react-router-dom";

function PlaceOrder({ checkOut }) {
  return (
    <div className="col-lg-6 billing-inner order-form">
      <div className="cart-collaterals">
        <div className="cart_totals">
          <h6>Your order</h6>
          <div className="shop_table py-2">
            <div>
              <div className="cart-row d-flex justify-content-between">
                <span className="fw-bold  py-2">Subtotal</span>
                <span className="text-end py-2">Rs {checkOut.subTotal}</span>
              </div>
              <div className="cart-row d-flex justify-content-between">
                <td className="fw-bold  py-2">Shipping</td>
                <td className="text-end py-2">
                  <p className=" px-1 m-0 shop-content">Rs {checkOut.shipping} </p>
                </td>
              </div>

              {/* <tr className="cart-row">
                    <td className="fw-bold  py-2">VAT</td>
                    <td className="text-end py-2">₹0</td>
                  </tr> */}
              <div className="d-flex justify-content-between">
                <td className="fw-bold  py-3">Total</td>
                <h6 className="text-end py-3 text-danger fw-semibold">
                  Rs {checkOut.total}
                </h6>
              </div>
            </div>
            {/* <div className="checkout py-4">
              <input type="checkbox" id="check2" />
              <label htmlFor="check2">
                I would like to receive exclusive emails with discounts and
                product information
              </label>
            </div> */}
            <p className="privacy-policy">
              Your personal data will be used to process your order, support
              your experience throughout this website, and for other purposes
              described in our
              <Link className="text-danger">privacy policy.</Link>
            </p>
          </div>
          <button type="submit" className="checkout-button text-light">
            {/* <Link to=""> */}
            Place Order
            {/* </Link> */}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PlaceOrder;
