import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { singleProductApi } from "../../../Components/services/singleProduct.service";
import "../productDetialPage/productDetialPage.scss";
import { IoIosArrowForward } from "react-icons/io";
import { API_BASE_URL } from "../../../utils/use";
import axios from "axios";
import { getallCartApi } from "../../../Components/services/getAllCart.service";
import { useAuthHeaders } from "../../../Components/Token";
import userContext from "../../../Components/UseContext/UseContext";
import google from "../../../assets/images/google_plus_icon.png";
import twitter from "../../../assets/images/Twitter.png";
import Instagram from "../../../assets/images/Instagram.png";
import facebook from "../../../assets/images/Facebook.png";
import ImageGallery from "react-image-gallery";
import TriangleLoader from "../../../Components/Loader/TriangleLoader";
import EnquiryButton from "../../../Components/Cards/EnquiryButton";
import WhatsApp from "../../../Components/MinoreComponents/WhatsApp";

function ProductDetialPage() {
  const { _id } = useParams();
  const [detail, setDetail] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const headers = useAuthHeaders();
  const colors = detail?.Productcolor;
  const store = useContext(userContext);

  const [quantity, setQuantity] = useState(1);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await singleProductApi({ _id });

        if (response && response.data) {
          setDetail(response.data.product);

          const imageArray = response.data.product.productimages.map((img) => ({
            original: img.productimage,
            thumbnail: img.productimage,
          }));
          setImages(imageArray);
        } else {
          console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("API call failed", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getallCartApi({ headers });

        if (response && response.data) {
          // console.log("lokiiii :::", response.data.cartItems);
          // store.setAllProducts(response.data);
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } catch (error) {
        console.error("API call failed", error);
      }
    };

    fetchData();
  }, []);

  const handleIncrement = async () => {
    try {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);

      const response = await axios.put(
        `${API_BASE_URL}/user/order/updateaddtocart/${_id}`,
        {
          quantity: newQuantity,
        },
        {
          headers,
        }
      );

      if (response.status === 200) {
        const cartResponse = await getallCartApi({ headers });

        if (cartResponse && cartResponse.data) {
          store.setAllProducts(cartResponse.data);
        } else {
          console.error("Unexpected response structure:", cartResponse.data);
        }
      } else {
        // Handle error
        console.error("Error updating quantity:", response);
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
    }
  };

  const handleDecrement = async () => {
    try {
      // Decrease quantity, but not below 1
      const newQuantity = Math.max(quantity - 1, 1);
      setQuantity(newQuantity);

      // Update the quantity on the server
      const response = await axios.put(
        `${API_BASE_URL}/user/order/updateaddtocart/${_id}`,
        {
          quantity: newQuantity,
        },
        {
          headers,
        }
      );

      if (response.status === 200) {
        const cartResponse = await getallCartApi({ headers });

        if (cartResponse && cartResponse.data) {
          store.setAllProducts(cartResponse.data);
        } else {
          console.error("Unexpected response structure:", cartResponse.data);
        }
      } else {
        // Handle error
        console.error("Error updating quantity:", response);
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
    }
  };

  const handleQuantityChange = (e) => {
    // Handle direct input changes
    const newQuantity = parseInt(e.target.value, 10) || 1;
    setQuantity(newQuantity);
  };

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

  if (detail === null) {
    return (
      <div
        className="w-100 d-flex justify-content-center"
        style={{ margin: "4rem 0rem" }}
      >
        <TriangleLoader />
      </div>
    );
  } else {
    return (
      <div className="container detail-container">
        <div className="my-2">
          <Link to="/" className="text-dark">
            Home <IoIosArrowForward />
          </Link>
          <Link className="text-dark">
            {detail.categoryname} <IoIosArrowForward />{" "}
          </Link>

          <Link className="text-dark">
            {detail.subcategoryname} <IoIosArrowForward />
          </Link>

          <Link className="text-dark fw-light">{detail.productName}</Link>
        </div>

        <div className="detail-section my-4 row m-0 ">
          <div className="col-md-4 detail-section-content">
            <ImageGallery items={images} />
          </div>

          <div className="col-md-8 detail-section-content">
            <div className="detail-content h-100">
              <h3 className="detial-title">{detail.productName}</h3>

              <div className="price d-flex align-items-center">
                <span className="current-price mx-1">
                  ₹ {detail.saleprice}{" "}
                </span>
                <span className="hide-price">₹ {detail.originalprice} </span>
              </div>

              <div className="d-flex align-items-center">
                <label htmlFor="category" className="fw-semibold">
                  Category :
                </label>
                <p className="p-2 m-0">{detail.categoryname}</p>
              </div>

              <div className="d-flex align-items-center">
                <label htmlFor="category" className="fw-semibold">
                  Sub Category :
                </label>
                <p className="p-2 m-0">{detail.subcategoryname} </p>
              </div>

              {detail?.description &&
                detail?.description
                  .filter((item) => item !== null) 
                  .map((item, i) => (
                    <div className="d-flex my-2" key={i}>
                      <label htmlFor="" className="fw-semibold">
                        {item?.name} :
                      </label>
                      <span className="mx-2" style={{ color: "#F36537" }}>
                        {item?.value}
                      </span>
                    </div>
                  ))}

              <div className="product-colors my-4">
                {colors.map((color, index) => (
                  <div key={index} onClick={() => handleImageSelect(color)}>
                    <div
                      className={`color ${
                        selectedImage === color ? "active" : ""
                      }`}
                      style={{ backgroundColor: color }}
                    ></div>
                  </div>
                ))}
              </div>

              <div className="d-flex align-items-center">
                <EnquiryButton
                  detail={detail}
                  _id={_id}
                  type="product_button"
                  selectedImage={selectedImage}
                />

                <WhatsApp className="mx-3" />
              </div>

              <div
                className="d-flex align-items-center"
                style={{ marginTop: "40px" }}
              >
                <label htmlFor="share" className="fw-semibold">
                  Share :
                </label>
                <img
                  src={google}
                  alt="social-media"
                  className="social-media mx-2"
                />
                <img
                  src={twitter}
                  alt="social-media"
                  className="social-media mx-2"
                />
                <img
                  src={Instagram}
                  alt="social-media"
                  className="social-media mx-2"
                />
                <img
                  src={facebook}
                  alt="social-media"
                  className="social-media mx-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductDetialPage;
