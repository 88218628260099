import axios from "axios";
import { API_BASE_URL } from "../../utils/use";
import { useAuthHeaders } from "../Token";

export const profileApi = async (headers) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/myprofile`, {
      headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
