import React, { useEffect, useState } from "react";
import Banner from "../../Components/Banner/Banner";
import Offers from "../../Components/Offers/Offers";
import OfficeFurniture from "../../Components/OfficeFurniture/OfficeFurniture";
import Products from "../../Components/Products/Products";
import BestSeller from "../../Components/BestSeller/BestSeller";
import LatestNews from "../../Components/LatestNews/LatestNews";
import Testimonials from "../../Components/Testimonials/Testimonials";
import Client from "../../Components/Client/Client";
import "../Home/home.scss";
import AddCartFixedButton from "../../Components/Buttons/AddCartFixedButton";
import axios from "axios";
import { API_BASE_URL } from "../../utils/use";
import { NavLink } from "react-router-dom";

function Home() {
  const [data, setData] = useState([]);
  const [firstcategoryName, setFirstCategoryName] = useState([]);
  const [secoundcategoryName, setSecoundCategoryName] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        const activeKeySetinCategory = response.data.categories
          .slice(0, 4)
          .map((category, index) => ({
            ...category,
            activeKey: index.toString(),
          }));

        setData(activeKeySetinCategory);

        const categories = response.data.categories.slice(0, 4);

        const firstCategoryName =
          categories.length > 0 ? categories[0].name : null;

        setFirstCategoryName(firstCategoryName);

        const secoundCategoryName =
          categories.length > 0 ? categories[1].name : null;

        setSecoundCategoryName(secoundCategoryName);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="position-relative">
      <Banner firstcategoryName={firstcategoryName} />

      <div className="position-relative">
        <Offers categoryName={firstcategoryName} />
        <OfficeFurniture categoryName={secoundcategoryName} />
        <h1 className="side-branding">Chennai Furniture</h1>
      </div>

      <div id="category">
        <Products selectCategory="0" data={data} />
      </div>

      <BestSeller />
      <Products selectCategory="1" data={data} />
      <LatestNews />
      <Testimonials />
      <Client />
    </div>
  );
}

export default Home;
