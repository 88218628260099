import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { loginApi } from "../../../Components/services/login.service";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { resetPassWordApi } from "../../../Components/services/resetPassword.service";

function ResetPassWord() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { reset } = useParams();

  const validationSchema = Yup.object({
    // email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .min(6, "Confirm Password must be at least 6 characters")
      .required("Confirm Password is required"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const handleResetPassword = async (data) => {
    console.log("reset passwords", data);
    try {
      const response = await resetPassWordApi(data, reset);

      if (response && response.data) {
        console.log("login successful", response.data);
        navigate("/login");
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.log("error", error.response.data.message);
      Swal.fire({
        icon: "error",
        // title: "Oops...",
        text: error.response.data.message,
      });
    }
  };

  return (
    <form
      action=""
      className="my-4"
      onSubmit={handleSubmit(handleResetPassword)}
    >
      <MDBContainer
        fluid
        className="background-radial-gradient overflow-hidden w-100"
      >
        <MDBRow>
          <MDBCol
            md="6"
            className="position-relative d-flex justify-content-center w-100"
          >
            <div
              id="radius-shape-1"
              className="position-absolute rounded-circle shadow-5-strong"
            ></div>
            <div
              id="radius-shape-2"
              className="position-absolute shadow-5-strong"
            ></div>

            <MDBCard className="my-5 bg-glass">
              <MDBCardBody className="px-4">
                <div className="eye-container my-4">
                  <label htmlFor="form7">Password</label>
                  <MDBInput
                    wrapperClass=""
                    id="form7"
                    type={showPassword ? "text" : "password"}
                    required
                    placeholder="Password"
                    {...register("password")}
                    onChange={(e) => setValue("username", e.target.value)}
                  />
                  <span
                    className="input-group-text eye-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>

                <p className="text-danger m-0">
                  {errors && errors?.password?.message}
                </p>

                <div className="eye-container my-4">
                  <label htmlFor="form7">Confirm Password</label>
                  <MDBInput
                    wrapperClass=""
                    id="form7"
                    type={showConfirmPassword ? "text" : "password"}
                    required
                    placeholder="Password"
                    {...register("confirmPassword")}
                    onChange={(e) => setValue("username", e.target.value)}
                  />
                  <span
                    className="input-group-text eye-icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>

                <p className="text-danger m-0">
                  {errors && errors?.password?.message}
                </p>

                <button className="w-100 btn btn-dark" size="md" type="submit">
                  Continue
                </button>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </form>
  );
}

export default ResetPassWord;
