import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { registerApi } from "../../../Components/services/register.service";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../register/register.scss";

function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = Yup.object({
    username: Yup.string().required("Name is required"),
    mobile: Yup.string().required("Mobile is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const handleRegister = async (data) => {
    try {
      const response = await registerApi(data);

      if (response && response.data) {
        Swal.fire(response.data.message);
        navigate("/login");
      } else {
        Swal.fire({
          title: "Oops...",
          text: response?.data?.message || "Unexpected response structure",
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };

  return (
    <form action="" className="my-4" onSubmit={handleSubmit(handleRegister)}>
      <MDBContainer
        fluid
        className="background-radial-gradient overflow-hidden"
      >
        <MDBRow>
          <MDBCol
            md="6"
            className="position-relative d-flex justify-content-center w-100"
          >
            <div
              id="radius-shape-1"
              className="position-absolute rounded-circle shadow-5-strong"
            ></div>
            <div
              id="radius-shape-2"
              className="position-absolute shadow-5-strong"
            ></div>

            <MDBCard className="my-5 bg-glass text-dark">
              <MDBCardBody className="p-4">
                <MDBRow>
                  <MDBCol col="6">
                    <label htmlFor="form1">Name</label>
                    <MDBInput
                      wrapperClass="mb-2 custom-placeholder-color"
                      id="form1"
                      type="text"
                      placeholder="Name"
                      required
                      {...register("username")}
                      onChange={(e) => setValue("username", e.target.value)}
                    />
                    <p className="text-danger">
                      {errors && errors?.username?.message}
                    </p>
                  </MDBCol>

                  <MDBCol col="6">
                    <label htmlFor="form2">Mobile</label>

                    <MDBInput
                      wrapperClass="mb-2"
                      id="form2"
                      type="text"
                      required
                      placeholder="Mobile"
                      {...register("mobile")}
                      // onChange={(e) => setMobile(e.target.value)}
                      onChange={(e) => setValue("mobile", e.target.value)}
                    />
                  </MDBCol>
                  <p className="text-danger">
                    {errors && errors?.mobile?.message}
                  </p>
                </MDBRow>

                <label htmlFor="form3">Email</label>

                <MDBInput
                  wrapperClass="mb-2"
                  id="form3"
                  type="email"
                  required
                  placeholder="Email"
                  {...register("email")}
                  onChange={(e) => setValue("email", e.target.value)}
                />
                <p className="text-danger">
                  {errors && errors?.email?.message}
                </p>

                <div className="eye-container">
                  <label htmlFor="form4">Password</label>
                  <MDBInput
                    wrapperClass="mb-2"
                    id="form4"
                    type={showPassword ? "text" : "password"}
                    required
                    placeholder="Password"
                    {...register("password")}
                    onChange={(e) => setValue("password", e.target.value)}
                  />

                  <span
                    className="input-group-text eye-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
                <p className="text-danger">
                  {errors && errors?.password?.message}
                </p>

                <div className="eye-container">
                  <label htmlFor="form5">Confirm Password</label>

                  <MDBInput
                    wrapperClass="mb-4"
                    id="form5"
                    type={showConfirmPassword ? "text" : "password"}
                    required
                    placeholder="Confirm Password"
                    {...register("confirmPassword")}
                    onChange={(e) =>
                      setValue("confirmPassword", e.target.value)
                    }
                  />

                  <span
                    className="input-group-text eye-icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
                <p className="text-danger">
                  {errors && errors?.confirmPassword?.message}
                </p>

                <button className="w-100 btn btn-dark" size="md" type="submit">
                  Register
                </button>
              </MDBCardBody>
              <div className="text-center" style={{ marginBottom: "10px" }}>
                <Link to="/login" className="w-100">
                  Alredy Register, Go To Login
                </Link>
              </div>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </form>
  );
}

export default Register;
