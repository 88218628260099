import React, { useEffect } from "react";
import "../Banner/banner.scss";
import "../../Common/common.scss";
import Banner_Green_Chair from "../../assets/images/Banner Green Chair.png";
import Banner_Blue_Chair from "../../assets/images/Banner Blue Chair.png";
import Banner_Orange_Chair from "../../assets/images/Banner orange Chair.png";
import Slider from "react-slick";
import AOS from "aos";
import { Link } from "react-router-dom";

function Banner({ firstcategoryName }) {
  const banner = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: false,
    prevArrow: false,
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const slides = [
    {
      title: "Best Design of Furniture Collection",
      description: "Crafting Comfort, Crafting Quality.",
      link: "/products",
      image1: Banner_Green_Chair,
      image2: Banner_Blue_Chair,
      image3: Banner_Orange_Chair,
    },
    {
      title: "Best Design of Furniture Collection",
      description: "Crafting Comfort, Crafting Quality.",
      link: "/products",
      image1: Banner_Green_Chair,
      image2: Banner_Blue_Chair,
      image3: Banner_Orange_Chair,
    },
  ];

  return (
    <div className="container banner-container">
      <Slider {...banner}>
        {slides.map((slide, index) => (
          <div key={index} className="banner">
            <div
              className="banner-content"
              data-aos="fade-down"
              data-aos-delay="600"
              data-aos-duration="500"
            >
              <h1>{slide.title}</h1>
              <p>{slide.description}</p>
              <Link to={`/products/${firstcategoryName}`}>
                <button className="primary-button px-4">Shop Now</button>
              </Link>
            </div>
            <div className="banner-images">
              <div
                className="chair chair-one"
                data-aos="fade-left"
                data-aos-delay="600"
                data-aos-duration="500"
              >
                <img src={slide.image1} alt="Banner_Image_1" />
                <div className="chairs-background"></div>
              </div>
              <div
                className="chair chair-two"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="500"
              >
                <img src={slide.image2} alt="Banner_Image_2" />
                <div className="chairs-background"></div>
              </div>
              <div
                className="chair chair-three"
                data-aos="fade-right"
                data-aos-delay="600"
                data-aos-duration="500"
              >
                <img src={slide.image3} alt="Banner_Image_3" />
                <div className="chairs-background"></div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Banner;
