import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import ProductPreview from "../productPreview/ProductPreview";

function PreviewButton({ data }) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="sub-icons-wrapper">
        <AiOutlineEye
          onClick={() => setModalShow(true)}
          className="sub-icons"
        />
      </div>

      <ProductPreview data={data} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default PreviewButton;
