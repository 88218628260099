import axios from "axios";
import { API_BASE_URL } from "../../utils/use";

export const loginApi = async (data) => {
  const { email, password } = data;
  
  return await axios.post(`${API_BASE_URL}/user/login`, {
    email: email,
    password: password,
  });
};
