import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Wishlist from "./Pages/wishlist/Wishlist";
import CheckOut from "./Pages/checkOut/CheckOut";
import Login from "./Pages/AuthPages/login/Login";
import Register from "./Pages/AuthPages/register/Register";
import Profile from "./Pages/profile/Profile";
import { AuthProvider } from "./Components/Token";
import userContext from "./Components/UseContext/UseContext";
import ViewAllProducts from "./Pages/Home/viewAllProducts/ViewAllProducts";
import ProductDetialPage from "./Pages/Home/productDetialPage/ProductDetialPage";
import { ToastContainer } from "react-toastify";
import Contact from "./Pages/Home/contact/Contact";
import ScrollToTop from "./Components/scrollToTop/ScrollToTop";
import AddCartFixedButton from "./Components/Buttons/AddCartFixedButton";
import ForgetPassword from "./Pages/AuthPages/forgetPassword/ForgetPassword";
import ResetPassWord from "./Pages/AuthPages/resetPassWord/ResetPassWord";

function App() {
  const [wishlistLength, setWishlistLength] = useState(0);
  const [wishlist, setWishlist] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [filterCategoryResults, setFilterCategoryResults] = useState([]);
  const [filterColorResults, setFilterColorResults] = useState([]);
  const [filterMaterialResults, setfilterMaterialResults] = useState([]);
  const [filterAllProductsResults, setFilterAllProductsResults] = useState([]);
  const [allProductSubtotal, setAllProductSubtotal] = useState("");
  const [wishlistLocal, setWishlistLocal] = useState([]);
  const [wishlistLengthLocal, setWishlistLengthLocal] = useState(0);
  const [profileNumber, setProfileNumber] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const [searcLoader, setSearchLoader] = useState(false);

  const defaultValue = {
    wishlist,
    setWishlist,
    wishlistLength,
    setWishlistLength,
    allProducts,
    setAllProducts,
    filterCategoryResults,
    setFilterCategoryResults,
    filterColorResults,
    setFilterColorResults,
    filterMaterialResults,
    setfilterMaterialResults,
    filterAllProductsResults,
    setFilterAllProductsResults,
    allProductSubtotal,
    setAllProductSubtotal,
    wishlistLocal,
    setWishlistLocal,
    wishlistLengthLocal,
    setWishlistLengthLocal,
    profileNumber,
    setProfileNumber,
    searchResults,
    setSearchResults,
    searcLoader,
    setSearchLoader,
  };

  useEffect(() => {
    localStorage.setItem("localWhislist", JSON.stringify(wishlistLocal));
  }, [wishlistLocal]);

  return (
    <userContext.Provider value={defaultValue}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route path="/resetPassWord/:reset" element={<ResetPassWord />} />
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/checkOut" element={<CheckOut />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/products/:slug" element={<ViewAllProducts />} />
              <Route path="/product/:_id" element={<ProductDetialPage />} />
              <Route
                path="/products/:slug/product/:_id"
                element={<ProductDetialPage />}
              />
            </Route>
          </Routes>
          <ScrollToTop />
          <AddCartFixedButton />
        </BrowserRouter>

        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        
      </AuthProvider>
    </userContext.Provider>
  );
}

export default App;