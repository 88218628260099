import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { API_BASE_URL } from "../../../utils/use";
import { useNavigate } from "react-router-dom";

function Search() {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [dropDown, setDropDown] = useState(false);
  const navicate = useNavigate();

  useEffect(() => {
    if (search.trim !== "") {
      axios
        .get(
          `${API_BASE_URL}/user/products/usersearchproducts?keyword=${search}`
        )
        .then((res) => {
          setSearchResults(res.data.relatedWords || []);
          const DropDownActive = res.data.relatedWords?.length > 0;
          setDropDown(DropDownActive);
        })
        .catch((error) => {
          console.log("error search", error);
        });
    } else {
      console.log("comming in else");
    }
  }, [search]);

  const handleSearchResults = () => {
    if (searchResults.length > 0) {
      navicate(`/products/${search}`);
      setTimeout(() => setDropDown(false));
      setSearch("");
      setDropDown(false);
    }
  };

  const handleBlure = () => {
    setTimeout(() => setDropDown(false), 200);
  };

  const onFocuse = () => {
    setDropDown(true);
  };

  const handleSelectResult = (result) => {
    setSearch(result);
    setDropDown(false);
    // handleSearchResults();
  };

  return (
    <div className="search-container">
      <div className="search">
        <input
          type="search"
          className="search-input"
          onBlur={handleBlure}
          onFocus={onFocuse}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search..."
        />
        <button onClick={handleSearchResults}>
          <IoSearch className="search-icon" />
        </button>
      </div>

      {dropDown === true && (
        <ul className="search-drop">
          {searchResults?.length === 0 ? (
            <span className="">No Results</span>
          ) : (
            <>
              {searchResults &&
                searchResults.map((result, index) => (
                  <li key={index} onClick={() => handleSelectResult(result)}>
                    {result}
                  </li>
                ))}
            </>
          )}
        </ul>
      )}
    </div>
  );
}

export default Search;
