import React from "react";
import product_banner from "../../../assets/images/Product_banner_image.png";
import Filters from "./Filters/Filters";
import FilterResults from "./FilterResultes/FilterResults";
import "../../../Common/common.scss"

function ViewAllProducts() {
  return (
    <>
      <div className="product-banner">
        <img
          className="product-banner-image"
          src={product_banner}
          alt="product_banner"
        />
      </div>
      <div className="container my-4">
        <div className="row">
          <div className="col-md-3">
            <Filters />
          </div>
          <div className="col-md-9">
            <FilterResults />
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewAllProducts;