import React, { useEffect, useState } from "react";
import "../BestSeller/bestSeller.scss";
import { IoMdStar } from "react-icons/io";
import { Card } from "react-bootstrap";
import axios from "axios";
import { API_BASE_URL } from "../../utils/use";
import { Link } from "react-router-dom";

function BestSeller() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}//user/products/getallproducts`)
      .then((res) => {
        setData(res.data.products.slice(-6));
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="best-seller">
      <div className="container">
        <Card className="best-seller-form">
          <h4 className="fw-bold">Best Selling Products</h4>
          <div className="best-seller-form-product-container">
            {data.map((seller, index) => (
              <Link
                to={`/product/${seller._id}`}
                className="best-seller-form-product"
                key={index}
              >
                <div className="best-seller-form-product-image">
                  <img
                    src={seller.productimages[0]?.productimage}
                    alt="best-products"
                  />
                </div>
                <div className="products-content mx-2">
                  <h6 className="chair-name m-0 text-black">
                    {seller.productName}
                  </h6>
                  <div className="reviews py-2">
                    <IoMdStar className="start" />
                    <IoMdStar className="start" />
                    <IoMdStar className="start" />
                    <IoMdStar className="start" />
                    <IoMdStar className="start" />
                    <p className="m-0">( 0 Reviews )</p>
                  </div>
                  <div className="price">
                    <span className="hide-price">
                      ₹ {seller.originalprice}{" "}
                    </span>
                    <span className="current-price mx-2">
                      ₹ {seller.saleprice}{" "}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
}

export default BestSeller;
