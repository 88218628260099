import React from "react";
import "../Testimonials/testimonials.scss";
import { FaQuoteRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import avater_01 from "../../assets/images/avatar-Icon-02.png";
import avater_02 from "../../assets/images/avatar-Icon-03.png";
import avater_03 from "../../assets/images/avatar-Icon-04.png";

function Testimonials() {
  const testimonials = [
    {
      text: "Chennai Furniture has truly elevated our office experience! Their stylish furniture not only impresses clients but also keeps us comfortable and productive all day long.",
      avatar: avater_01,
    },
    {
      text: "We're thrilled with Chennai Furniture's selection! From ergonomic chairs to sleek desks, their pieces have transformed our workspace into a modern and inviting environment.",
      avatar: avater_02,
    },
    {
      text: "Choosing Chennai Furniture was the best decision for our office. The quality and design of their furniture exceed expectations, making our workdays more enjoyable and efficient.",
      avatar: avater_03,
    },
  ];

  return (
    <div className="container testimonials">
      <center className="headering">
        <h4 className="fw-semibold">Testimonials</h4>
        <p className="fw-normal">See Why Our Customers Love Us</p>
      </center>

      <div className="testimonials-cards-container">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonials-cards">
            <div className="testimonials-card-content">
              <FaQuoteRight className="testimonials-cards-icon" />
              <p className="p-2">{testimonial.text}</p>
              <img
                src={testimonial.avatar}
                alt={`avater_${index}`}
                className="testimonials-cards-avater"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Testimonials;
