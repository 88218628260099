import React, { useEffect, useState } from "react";
import "../Furnitures/furnitures.scss";
import "../../OfficeFurniture/officeFurniture.scss";
import "../../Offers/offers.scss";
import Cards from "../../Cards/Cards";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/use";
import TriangleLoader from "../../Loader/TriangleLoader";

function Furnitures({ name }) {
  const [allProducts, setAllProducts] = useState([]);
  const [limit, setlimit] = useState(6);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}/user/products/userGetproductsbycategory?categoryname=${name}&limit=${limit}`
      )
      .then((res) => {
        setAllProducts(res.data.products);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  }, [limit, name]);

  const handleLoadMore = () => {
    setlimit((prev) => prev + 3);
  };

  return (
    <div className="products-container">
      {allProducts.length === 0 ? (
        <TriangleLoader errorMessage={errorMessage} />
      ) : (
        <>
          {allProducts.map((product, index) => (
            <Cards key={index} products={product} />
          ))}
        </>
      )}

      <center className="w-100">
        {/* <Link to="/viewAllProducts"> */}
        <button onClick={handleLoadMore} className="shopping-button">
          View More
        </button>
        {/* </Link> */}
      </center>
    </div>
  );
}

export default Furnitures;
