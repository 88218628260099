import React, { useContext, useState } from "react";
import WishlistButton from "../Buttons/WishlistButton";
import PreviewButton from "../Buttons/PreviewButton";
import { Link } from "react-router-dom";
import WhatsApp from "../MinoreComponents/WhatsApp";

function Cards({ products }) {
  const _id = products._id;

  return (
    <div className="products">
      <Link to={`product/${_id}`} className="text-decoration-none text-dark">
        <img
          src={products.productimages?.[0]?.productimage || null}
          className="products-image"
          alt="product1"
        />
      </Link>

      <h6 className="chair-name py-2 p-4">{products.productName}</h6>
      <div className="products-content products-content-two">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="price">
            <span className="hide-price">₹ {products.originalprice} </span>
            <span className="current-price mx-2">₹ {products.saleprice} </span>
          </div>

          <div className="d-flex align-items-center">
            <div>
              <WhatsApp productId={_id} />
            </div>
          </div>
        </div>
      </div>

      <div className="sale">SALE</div>
      <div className="sub-items">
        {/* <div className="sub-icons-wrapper">
          <IoShuffle className="sub-icons" />
        </div> */}
        <WishlistButton data={products} />
        <PreviewButton data={products} />
      </div>
    </div>
  );
}

export default Cards;
