import axios from "axios";
import { API_BASE_URL } from "../../utils/use";

export const forgetPasswordApi = async (data) => {
  const { email } = data;

  return await axios.post(`${API_BASE_URL}/user/password/forgot`, {
    email: email,
  });
};
