import React from "react";
import "../Footer/footer.scss";
import google from "../../assets/images/google_plus_icon.png";
import twitter from "../../assets/images/Twitter.png";
import Instagram from "../../assets/images/Instagram.png";
import facebook from "../../assets/images/Facebook.png";
import { Link } from "react-router-dom";
import paypal from "../../assets/images/paypal.png";
import visa from "../../assets/images/visa.png";
import master_card from "../../assets/images/master_card.png";
import american_express from "../../assets/images/american_express.png";
import discover from "../../assets/images/discover.png";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="footer">
        <div className="container footer-container">
          <div className="row">
            <div className="col-md-3 footer-detials section h-100">
              <h4 className="footer-detials-title">Chennai Furniture</h4>
              <p className="footer-link my-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Velit
                ducimus voluptatibus neque illo id repellat quisquam? Autem
                expedita earum quae laborum ipsum ad.
              </p>
              <img src={google} alt="social-media" className="social-media" />
              <img src={twitter} alt="social-media" className="social-media" />
              <img
                src={Instagram}
                alt="social-media"
                className="social-media"
              />
              <img src={facebook} alt="social-media" className="social-media" />
            </div>

            <div className="col-md-3 contact section h-100">
              <p className="sub-title">Contact us</p>
              <p>203, Second Street, Chennai, Tamil Nadu.</p>
              <a href="tel:0123456789" className="text-dark">
                <p>Phone: 0123 456 789</p>
              </a>
            </div>

            <div className="col-md-3 col-sm-6 links section section-secound h-100">
              <p className="sub-title">Useful links</p>
              <ul>
                <li className="footer-link">
                  <Link to="/">Home</Link>
                </li>
                <li className="footer-link">
                  <Link>Categery</Link>
                </li>
                <li className="footer-link">
                  <Link to="/viewAllProducts">shop</Link>
                </li>
                <li className="footer-link">
                  <Link>Blogs</Link>
                </li>
                <li className="footer-link">
                  <Link>About Us</Link>
                </li>
                <li className="footer-link">
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3 col-sm-6 links section section-secound h-100">
              <p className="sub-title">information</p>
              <ul>
                <li className="footer-link">
                  <Link>Terms & Conditions</Link>
                </li>
                <li className="footer-link">
                  <Link>Privacy Policy</Link>
                </li>
                <li className="footer-link">
                  <Link>Warranty</Link>
                </li>
                <li className="footer-link">
                  <Link>Blogs</Link>
                </li>
                <li className="footer-link">
                  <Link>Return & Refund Policy</Link>
                </li>
                <li className="footer-link">
                  <Link>Shipping & Return Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="copy-rights">
        <div className="container py-2 d-flex flex-wrap align-items-center justify-content-between">
          <p className="m-0">
            © {currentYear} .All Right Reserved. Design and Developed by{" "}
            <Link to="https://webdads2u.com/" target="_blank">
              WEBDADS2U PVT LTD
            </Link>
          </p>
          <div className="payments">
            <img src={paypal} alt="paypal" />
            <img src={visa} alt="visa" />
            <img src={master_card} alt="master_card" />
            <img src={american_express} alt="american_express" />
            <img src={discover} alt="discover" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
