import React from "react";
import Modal from "react-bootstrap/Modal";
import product1 from "../../assets/images/Product1.png";

function ProductPreview(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body>
        <div className="row d-flex align-items-center justify-content-center text-center">
          <div className="col-sm-6">
            <img
              src={props.data.productimages?.[0]?.productimage}
              height={250}
              width={250}
              alt="product_image"
              style={{ objectFit: "contain" }}
            />
          </div>
          <div className="col-sm-6">
            <h6>Product : {props.data.productName}</h6>
            <p>Price :{props.data.saleprice}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ProductPreview;
