import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "./use";
import { getallCartApi } from "../Components/services/getAllCart.service";
import { wishlist } from "../Components/services/wishlist.service";

const wishlistAfterLogin = async (productId, headers, data, productContext) => {
  const isLoggedIn = localStorage.getItem("tokenId");

  if (isLoggedIn) {
    try {
      await wishlist({ headers, productId, productContext });
      productContext.setWishlist((prevWishlist) => [...prevWishlist, data]);
    } catch (error) {
      console.log("addToCartAfterLogin catch", error);
    }
  } else {
    // non login
    const existingCart =
      JSON.parse(localStorage.getItem("localWhislist")) || [];

    const filterSameProducts = existingCart.filter(
      (item) => item._id !== data._id
    );

    const updatedCart = [...filterSameProducts, data];
    localStorage.setItem("localWhislist", JSON.stringify(updatedCart));

    productContext.setWishlistLengthLocal(updatedCart.length);
    localStorage.setItem(
      "whislistLengthLocal",
      JSON.stringify(updatedCart.length)
    );
    toast("Wishlist Successful");
  }
};

const cartRemove = async (headers, product, store) => {
  const isLoggedIn = localStorage.getItem("tokenId");

  if (isLoggedIn) {
    const response = await axios.delete(
      `${API_BASE_URL}/user/order/deleteaddtocart/${product.product._id}`,
      {
        headers,
      }
    );

    if (response.status === 200) {
      const cartResponse = await getallCartApi({ headers });

      if (cartResponse && cartResponse.data) {
        store.setAllProducts(cartResponse.data);
        localStorage.setItem("cartLength", cartResponse.data.cartLength);
      } else {
        console.error("Unexpected response structure:", cartResponse.data);
      }
    } else {
      // Handle error
      console.error("Error updating quantity:", response);
    }
  } else {
    const cartItems = JSON.parse(localStorage.getItem("storeLocalCart")) || [];
    const delectProduct = cartItems.filter(
      (items) => items._id !== product._id
    );

    store.setLocalCCart(delectProduct);

    // store.allProducts;
    // store.allProductSubtotal;

    localStorage.setItem("cartLengthLocal", delectProduct.length);
    localStorage.setItem("storeLocalCart", JSON.stringify(delectProduct));
  }
};

export { cartRemove, wishlistAfterLogin };
