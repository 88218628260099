import React, { useContext } from "react";
import { Link } from "react-router-dom";
import WishlistButton from "../../../../Components/Buttons/WishlistButton";
import PreviewButton from "../../../../Components/Buttons/PreviewButton";
import TriangleLoader from "../../../../Components/Loader/TriangleLoader";
import WhatsApp from "../../../../Components/MinoreComponents/WhatsApp";
import userContext from "../../../../Components/UseContext/UseContext";

function ProductVertical({ allProducts }) {
  const context = useContext(userContext);
  const loader = context?.searcLoader;

  return (
    <div className="view-all-products-container">
      <div className="products-container">
        {loader === true ? (
          <TriangleLoader />
        ) : (
          <>
            {allProducts.length === 0 ? (
              <h4 className="text-danger my-4">No Products</h4>
            ) : (
              <>
                {allProducts.map((products, index) => (
                  <div className="all-products-lines" key={index}>
                    <div className="products-card text-dark">
                      <div className="products-image-container">
                        <Link to={`product/${products._id}`}>
                          <img
                            src={
                              products.productimages?.[0]?.productimage || null
                            }
                            className="products-image"
                            alt="product1"
                          />
                        </Link>

                        <div className="sub-items">
                          <WishlistButton data={products} />
                          <PreviewButton data={products} />
                        </div>
                      </div>

                      <div className="products-content products-content-two text-dark">
                        <Link to={`product/${products._id}`}>
                          <h6 className="chair-name text-dark">
                            {products.productName}
                          </h6>
                          <p className="m-0 products-writenotes">
                            {products.writenotes}
                          </p>
                        </Link>

                        <div className="d-flex align-items-center justify-content-between">
                          <Link to={`product/${products._id}`}>
                            <div className="price">
                              <span className="hide-price">
                                ₹ {products.originalprice}{" "}
                              </span>
                              <span className="current-price mx-2">
                                ₹ {products.saleprice}{" "}
                              </span>
                            </div>
                          </Link>

                          <WhatsApp />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ProductVertical;
