import React, { useContext, useEffect, useState } from "react";
import "../viewAllProducts.scss";
import { TfiLayoutGrid2Alt, TfiLayoutListThumbAlt } from "react-icons/tfi";
import userContext from "../../../../Components/UseContext/UseContext";
import ProductHorizontal from "./ProductHorizontal";
import ProductVertical from "./ProductVertical";
import { getallcategoryApi } from "../../../../Components/services/getallcategory.service";
import axios from "axios";
import { API_BASE_URL } from "../../../../utils/use";
import { useParams } from "react-router-dom";

function FilterResults() {
  const [activeTab, setActiveTab] = useState("first");
  const { slug } = useParams();

  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
  };

  const storeResults = useContext(userContext);
  const { setSearchResults, searchResults } = storeResults;


  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await getallproductsApi();
        const response = await axios.get(
          `${API_BASE_URL}/user/products/usersearchproducts?keyword=${slug}`
        );

        if (response && response.data) {
          // console.log("Slug successful", response.data.products);

          setSearchResults(response.data.products);
        } else {
          console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("API call failed", error);
      }
    };

    fetchData();
  }, [slug]);

  return (
    <>
      <div className="my-4 filter-resultes-tab">
        <div
          className="tab"
          id="left-tabs-example"
          defaultActiveKey={activeTab}
        >
          <TfiLayoutGrid2Alt
            eventKey="first"
            tabIndex="0"
            className={`tab-icons ${activeTab === "second" ? "active" : ""}`}
            onClick={() => handleTabClick("first")}
          />
          <TfiLayoutListThumbAlt
            eventKey="second"
            tabIndex="0"
            className={`tab-icons ${activeTab === "second" ? "active" : ""}`}
            onClick={() => handleTabClick("second")}
          />

          <label htmlFor="cars">Showing all results</label>
        </div>

        <div className="show-limit">
          <label htmlFor="show-cars">Show</label>
          <select name="cars" id="cars" className="mx-3 px-1">
            <option value="volvo">6</option>
            <option value="saab">9</option>
            <option value="mercedes">12</option>
            <option value="audi">15</option>
          </select>
        </div>

        <div className="short-list">
          <label htmlFor="sort-cars">Short By </label>
          <select name="cars" id="cars" className="mx-3">
            <option>Default sorting</option>
            <option>Short by latest </option>
            <option>Short by popularity</option>
            <option>Short by price: low to high </option>
          </select>
        </div>
      </div>

      <div>
        {activeTab === "first" && (
          <>
            <ProductHorizontal allProducts={searchResults} />
          </>
        )}

        {activeTab === "second" && (
          <>
            <ProductVertical allProducts={searchResults} />
          </>
        )}
      </div>
    </>
  );
}

export default FilterResults;
