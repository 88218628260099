import React from "react";
import "../Navbar/navbar.scss";
import { AiOutlineHeart } from "react-icons/ai";
import ResponsiveNavbar from "../ResponsiveNavbar/ResponsiveNavbar";
import { Link } from "react-router-dom";
import Search from "./Search";

function Navbar() {
  return (
    <div className="navbar">
      <div className="container">
        <div className="navbar-inner">
          <Link to="/" className="branding text-dark text-decoration-none">
            CHENNAI FURNITURE
          </Link>

          <div className="d-flex">
            <Search />

            <div className="nav-items">
              <Link to="/wishlist" className="text-dark">
                <AiOutlineHeart className="nav-icons wishlist" />
              </Link>
            </div>
          </div>

          <ResponsiveNavbar />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
