import React from "react";
import product_banner from "../../../assets/images/Product_banner_image.png";
import "../../Home/contact/contact.scss";
import location_icons from "../../../assets/images/location_icons.png";
import phone_icon from "../../../assets/images/phone_icon.png";
import clock_icon from "../../../assets/images/clock_icon.png";
import { useLocation } from "react-router-dom";

function Contact() {
  const location = useLocation();

  return (
    <div className="overflow-x-hidden">
      <div className="product-banner">
        <img
          className="product-banner-image"
          src={product_banner}
          alt="product_banner"
        />
      </div>
      <div className="py-4 px-2 container">
        <center>
          <div className="contact-info">
            <h3 className="fw-bold contact-title">Get In Touch With Us</h3>
            <p className="contact-content">  
              For More Information About Our Product & Services. Please Feel
              Free To Drop Us An Email. Our Staff Always Be There To Help You
              Out. Do Not Hesitate
            </p>
          </div>
        </center>

        <iframe
          className="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15551.737848752866!2d80.17403308715818!3d12.97604360000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525e75fb761805%3A0x329689b7656a4104!2sChennai%20Furniture!5e0!3m2!1sen!2sin!4v1704637936710!5m2!1sen!2sin"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>

        <div className="row my-4 contact-container">
          <div className="col-lg-4 m-2">
            <div className="d-flex">
              <div>
                <img src={location_icons} alt="location_icons" />
              </div>
              <div className="mx-3">
                <h5 className="fw-semibold">Address</h5>
                <p className="contact-content">203, Second Street, Chennai, Tamil Nadu</p>
              </div>
            </div>
            <div className="d-flex">
              <div>
                <img src={phone_icon} alt="location_icons" />
              </div>
              <div className="mx-3">
                <h5 className="fw-semibold">Phone</h5>
                <p className="m-0 contact-content">mobile no: 0123 456 789</p>
                <p className="m-0 contact-content">Telephone no: 0123 456 789</p>
              </div>
            </div>
            <div className="d-flex my-3">
              <div>
                <img src={clock_icon} alt="location_icons" />
              </div>
              <div className="mx-3">
                <h5 className="fw-semibold">Open hours</h5>
                <p className="m-0 contact-content">Monday-Friday: 10:00am - 10:00pm</p>
                <p className="m-0 contact-content">Saturday-Sunday: 10:00am - 08:00pm </p>
              </div>
            </div>
          </div>

          <div className="col-lg-7">
            <h2 className="form-title">Leave A Message</h2>
            <p className="contact-content">Use the form below to get in touch with the sales team</p>

            <form className="contact-form" action="mailto:" method="post">
              <div className="input-wrapper">
                <input type="text" name="name" id="" placeholder="Your Name" required />
                <input type="email" name="email" id="" placeholder="Your Email" required />
              </div>
              <div className="input-wrapper">
                <input type="number" name="phone" id="" placeholder="Phone" required />
                <input type="text" name="subject" id="" placeholder="Subject" required />
              </div>
              <div className="d-flex flex-column">
                <label className="fw-bold my-2" htmlFor="">
                  Message
                </label>
                <textarea
                  id="story"
                  name="story"
                  rows="5"
                  cols="33"
                  className="textarea"
                  type="textarea"
                  required
                />
              </div>
              <button type="submit" value="submit" className="submit-button">Submit</button>
            </form>

          </div>


        </div>
      </div>
    </div>
  );
}

export default Contact;