import React, { createContext, useContext } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const tokenId = localStorage.getItem("tokenId");

  const headers = {
    Authorization: `Bearer ${tokenId}`,
  };

  return (
    <AuthContext.Provider value={headers}>{children}</AuthContext.Provider>
  );
};

export const useAuthHeaders = () => {
  return useContext(AuthContext);
};