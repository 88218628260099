import axios from "axios";
import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { API_BASE_URL } from "../../utils/use";
import { useAuthHeaders } from "../Token";
import Swal from "sweetalert2/dist/sweetalert2.js";
import userContext from "../UseContext/UseContext";

const inputFields = [
  {
    type: "text",
    label: "User Name",
    placeholder: "Enter your name",
    id: "userName",
  },
  {
    type: "email",
    label: "Email Address",
    placeholder: "Enter your Email",
    id: "email",
  },
  {
    type: "tel",
    label: "Mobile Number",
    placeholder: "Enter your mobile number",
    id: "mobileNo",
  },
];

function EnquiryForm({
  show,
  handleClose,
  _id = { _id },
  detail,
  selectedImage,
}) {
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    mobileNo: "",
  });

  const numberContext = useContext(userContext);

  const headers = useAuthHeaders();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      userName: formData.userName,
      email: formData.email,
      mobilenno: formData.mobileNo,
      Productcolor: selectedImage,
    };

    axios
      .post(`${API_BASE_URL}/user/products/enquiryform/${_id}`, data)

      .then((res) => {
        console.log("res", res.data.message);
        Swal.fire(res.data.message);

        Swal.fire({
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });

        handleClose();

        setFormData({
          userName: "",
          email: "",
          mobileNo: "",
        });
      })
      .catch((error) => {
        console.log("error", error);
        if (error.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data?.message || "Unauthorized",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data?.error || "Something went wrong",
          });
        }
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Product Enquiry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="row enquiry-form">
            <div className="col-md-6">
              <img
                src={detail?.productimages[0]?.productimage}
                alt="product_image"
                height="150px"
                width="200px"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className="col-md-6">
              <div className="d-flex">
                {/* <label htmlFor="" className="product-label">
                  Product Name :
                </label> */}
                <span className="product-detials">{detail.productName}</span>
              </div>

              <div className="d-flex">
                <span className="product-detials">₹ {detail.saleprice}</span>
              </div>

              <div className="d-flex">
                <label htmlFor="" className="product-label">
                  Color :
                </label>
                <span className="product-detials">
                  {" "}
                  {selectedImage || (
                    <p className="text-danger">Select Your Color</p>
                  )}
                </span>
              </div>
            </div>
          </div>
          {inputFields.map((field, index) => (
            <Form.Group key={index} className="mb-3" controlId={field.id}>
              <Form.Label>{field.label}</Form.Label>

              <Form.Control
                type={field.type}
                placeholder={field.placeholder}
                value={formData[field.id]}
                onChange={handleChange}
                required
              />
            </Form.Group>
          ))}

          <div className="float-end">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" className="mx-3">
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EnquiryForm;
