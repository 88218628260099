import React, { useState } from "react";
import EnquiryForm from "./EnquiryForm";

function EnquiryButton({ _id, type, detail, selectedImage }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (type === "product_button") {
    return (
      <div className="d-flex">
        <button className="enquiry-button" onClick={handleShow}>
          Enquiry
        </button>

        <EnquiryForm
          handleClose={handleClose}
          show={show}
          _id={_id}
          detail={detail}
          selectedImage={selectedImage}
        />
      </div>
    );
  }

  if (type === "text_button") {
    return (
      <div className="d-flex">
        <button className="enquiry-text-button" onClick={handleShow}>
          Enquiry
        </button>

        <EnquiryForm
          handleClose={handleClose}
          show={show}
          _id={_id}
          detail={detail}
          selectedImage={selectedImage}
        />
      </div>
    );
  }
}

export default EnquiryButton;
