import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

function WishlistProducts({
  removeWishlist,
  wishlistProduct,
  localWhislist,
  removeLocal,
}) {
  const isLoggedIn = localStorage.getItem("tokenId");

  return (
    <>
      <tr>
        {isLoggedIn ? (
          <td
            className="px-3 border-right"
            onClick={() => removeWishlist(wishlistProduct.ProductId._id)}
          >
            <AiOutlineClose />
          </td>
        ) : (
          <td
            className="px-3 border-right"
            onClick={() => removeLocal(localWhislist)}
          >
            <AiOutlineClose />
          </td>
        )}

        {isLoggedIn ? (
          <Link
            to={wishlistProduct._id}
            className="text-decoration-none text-dark"
          >
            <td className="border-right">
              {wishlistProduct.ProductId?.productimages?.[0]?.productimage && (
                <img
                  src={wishlistProduct.ProductId.productimages[0].productimage}
                  alt="wishlistProducts-image"
                />
              )}
            </td>
          </Link>
        ) : (
          <Link
            // to={wishlistProduct._id}
            className="text-decoration-none text-dark"
          >
            <td className="border-right" style={{ width: "20%" }}>
              <img
                src={localWhislist.productimages[0].productimage}
                alt="wishlistProducts-image"
                className="text-center"
              />
            </td>
          </Link>
        )}

        {isLoggedIn ? (
          <td className="px-2 border-right" style={{ width: "40%" }}>
            <h6 className="wishlistProducts-name">
              {wishlistProduct.ProductId?.productName ||
                "Product Name Not Available"}
            </h6>
            <p className="fw-bold m-0">
              ₹{wishlistProduct.ProductId?.saleprice || "Price Not Available"}
            </p>
          </td>
        ) : (
          <td className="px-2 border-right" style={{ width: "40%" }}>
            <h6 className="wishlistProducts-name">
              {localWhislist.productName}
            </h6>
            <p className="fw-bold m-0">{localWhislist.saleprice}</p>
          </td>
        )}
      </tr>
    </>
  );
}

export default WishlistProducts;
