import React from "react";
import "../SubHeader/subHeader.scss";
import { Link } from "react-router-dom";

function SubHeader() {
  const tokenId = localStorage.getItem("tokenId");

  return (
    <div className="sub-header-wrapper py-2">
      <div className="container sub-header">
        <p className="m-0 left">
          Winter - Season sale Up To 25% OFF Use Coupon Code
        </p>
        <div className="right">
          <div className="authentication">
            {tokenId ? ( 
              <Link to="/profile" className="links">
                Profile
              </Link>
            ) : (
              <>
                <Link to="/login" className="links">
                  Login
                </Link>
                /
                <Link to="/register" className="links">
                  Register
                </Link>
              </>
            )}
          </div>
          <Link className="links">Help Center</Link>
        </div>
      </div>
    </div>
  );
}

export default SubHeader;