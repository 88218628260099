import React, { useContext, useEffect } from "react";
import userContext from "../../Components/UseContext/UseContext";
import { useAuthHeaders } from "../../Components/Token";
import { getallWishlistApi } from "../../Components/services/getAllWishlist.service";
import "../wishlist/wishlist.scss";
import axios from "axios";
import { API_BASE_URL } from "../../utils/use";
import WishlistProducts from "./WishlistProducts";

function Wishlist() {
  const store = useContext(userContext);
  const headers = useAuthHeaders();
  const isLoggedIn = localStorage.getItem("tokenId");
  const wishlistLocal = JSON.parse(localStorage.getItem("localWhislist"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getallWishlistApi({ headers });

        if (response && response.data) {
          // console.log("Get All Wishlist ::", response.data.wishlist);
          store.setWishlist(response.data.wishlist);
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } catch (error) {
        console.error("API call failed", error);
      }
    };

    fetchData();
  }, []);

  const removeWishlist = async (productId) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/user/deletewishlist/${productId}`,
        {
          headers,
        }
      );

      if (response.status === 200) {
        if (response.status === 200) {
          // Assuming you want to refresh the wishlist after deletion
          const updatedWishlist = await getallWishlistApi({ headers });
          store.setWishlist(updatedWishlist.data.wishlist);

          console.log("removeWishlist", response.data.wishlist);

          localStorage.setItem("wishlistLength", response.data.wishlist.length);
        } else {
          console.error("Error deleting item from wishlist:", response);
        }
      } else {
        // Handle error
        console.error("Error updating quantity:", response);
      }
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  const removeLocal = (productToRemove) => {
    const updatedWishlist = wishlistLocal.filter(
      (product) => product._id !== productToRemove._id
    );

    // console.log("updatedWishlist in local ::", updatedWishlist.length)

    localStorage.setItem("whislistLengthLocal", updatedWishlist.length)

    store.setWishlistLocal(updatedWishlist);
    localStorage.setItem("localWhislist", JSON.stringify(updatedWishlist));
  };

  return (
    <>
      <div className="listProducts-banner">
        <h2>Wishlist</h2>
      </div>
      <div className="listProducts container my-5">
        <div style={{ overflowX: "auto" }}>
          <table className="wishlist-products">
            <tbody>
              {isLoggedIn ? (
                <>
                  {store.wishlist.length ? (
                    <>
                      {store.wishlist.map((wishlistProduct) => {
                        return (
                          <WishlistProducts
                            removeWishlist={removeWishlist}
                            wishlistProduct={wishlistProduct}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <center>
                      <h5 className="text-danger">No items in the wishlist</h5>
                    </center>
                  )}
                </>
              ) : (
                <>
                  {wishlistLocal?.length ? (
                    <>
                      {wishlistLocal.map((item, i) => {
                        return <WishlistProducts localWhislist={item} removeLocal={removeLocal} key={i} />;
                      })}
                    </>
                  ) : (
                    <center>
                      <h5 className="text-danger">No items in the wishlist</h5>
                    </center>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Wishlist;
