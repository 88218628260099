import React from "react";
import { Triangle } from "react-loader-spinner";

function TriangleLoader({ errorMessage }) {
  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="text-center d-flex flex-column align-items-center">
        <Triangle
          visible={true}
          height="80"
          width="80"
          color="#F36537"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
        <span>{` ${
          errorMessage || `Still Now No Products Add By Admin... `
        } `}</span>
      </div>
    </div>
  );
}

export default TriangleLoader;