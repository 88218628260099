import React, { useContext } from "react";
import userContext from "../UseContext/UseContext";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

function WhatsApp({ className, productId }) {
  const context = useContext(userContext);
  // const number = context.profileNumber;
  const number = 7200742458;

  return (
    <Link
      to={`https://wa.me/${number}?text=https://chennaifurnitureonline.com/product/${productId}`}
      className={`product-w-link ${className}`}
      target="_blank"
    >
      <FaWhatsapp />
    </Link>
  );
}

export default WhatsApp;
