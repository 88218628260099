import React, { useEffect, useState } from "react";
import "../NavMenu/navMenu.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/use";

function NavMenu() {
  const handleScrollToProducts = () => {
    const productsSection = document.getElementById("category");

    if (productsSection) {
      productsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [firstcategoryName, setFirstCategoryName] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        const categories = response.data.categories.slice(0, 4);

        const firstCategoryName =
          categories.length > 0 ? categories[0].name : null;

        setFirstCategoryName(firstCategoryName);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="nav-menu">
      <ul>
        <li>
          <Link to="/" className="nav-links">
            HOME
          </Link>
        </li>
        <li>
          <Link onClick={handleScrollToProducts} className="nav-links">
            CATEGORY
          </Link>
        </li>
        <li>
          <Link to={`/products/${firstcategoryName}`} className="nav-links">
            SHOP
          </Link>
        </li>
        <li>
          <Link className="nav-links">BLOG</Link>
        </li>
        <li>
          <Link className="nav-links">ABOUT US</Link>
        </li>
        <li>
          <Link to="/contact" className="nav-links">
            CONTACT US
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default NavMenu;
