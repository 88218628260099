import axios from "axios";
import { API_BASE_URL } from "../../utils/use";

export const registerApi = async (data) => {
  //   console.log("Data in registerApi:", data);
  const { username, mobile, email, password, confirmPassword } = data;
  
  return await axios.post(`${API_BASE_URL}/user/register`, {
    name: username,
    mobile: mobile,
    email: email,
    password:password,
    confirmPassword: confirmPassword
  });
};