import React, { useContext } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import userContext from "../UseContext/UseContext";
import { wishlist } from "../services/wishlist.service";
import { useAuthHeaders } from "../Token";
import { wishlistAfterLogin } from "../../utils/cartUtils";

function WishlistButton({ data }) {
  const productContext = useContext(userContext);
  const headers = useAuthHeaders();

  const handleAddToWishlist = async () => {
    const productId = data._id;
    try{
      await wishlistAfterLogin(productId, headers, data, productContext)
    } catch (error) {
      console.log("error in Wishlist",error)
    }
  };

  return (
    <div
      className="sub-icons-wrapper wishlist-button"
      onClick={handleAddToWishlist}
    >
      <AiOutlineHeart className="sub-icons" />
    </div>
  );
}

export default WishlistButton;