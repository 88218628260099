import axios from "axios";
import { API_BASE_URL } from "../../utils/use";
import { toast } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.js";

export const wishlist = async ({ headers, productId, productContext }) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/user/wishlist`,
      {
        products: [{ ProductId: productId }],
      },
      {
        headers,
      }
    );
    
    productContext.setWishlistLength(response.data.wishlist.length)
    console.log("response.data.wishlist.length", response.data.wishlist)
    localStorage.setItem("wishlistLength", response.data.wishlist.length)
    toast("Wishlist Successful");

    return response.data;
  } catch (error) {
    Swal.fire({
      icon: "error",
      // title: "Oops...",
      title: "Login Again",
      text: error.response ? error.response.data.error : "An error occurred",
    });
  }
};