import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { Link, useNavigate } from "react-router-dom";
import { loginApi } from "../../../Components/services/login.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../login/login.scss";

function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const handleSignIn = async (data) => {
    // e.preventDefault();
    try {
      const response = await loginApi(data);

      if (response && response.data) {
        console.log("login successful", response.data);
        const tokenId = response.data.token;
        localStorage.setItem("tokenId", tokenId);
        // Swal.fire(response.data.message);
        navigate("/profile");
        window.location.reload(false);
      } else {
        console.error("Unexpected response structure:", response);
        // Swal.fire({
        //   // icon: "error",
        //   title: "Oops...",
        //   text: response.data.message,
        // });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        // title: "Oops...",
        text: error.response.data.message,
      });
    }
  };

  return (
    <form action="" className="my-4" onSubmit={handleSubmit(handleSignIn)}>
      <MDBContainer
        fluid
        className="background-radial-gradient overflow-hidden w-100"
      >
        <MDBRow>
          <MDBCol
            md="6"
            className="position-relative d-flex justify-content-center w-100"
          >
            <div
              id="radius-shape-1"
              className="position-absolute rounded-circle shadow-5-strong"
            ></div>
            <div
              id="radius-shape-2"
              className="position-absolute shadow-5-strong"
            ></div>

            <MDBCard className="my-5 bg-glass login-card">
              <MDBCardBody className="px-4">
                <label htmlFor="form6">Email</label>
                <MDBInput
                  wrapperClass="mb-2"
                  id="form6"
                  type="email"
                  required
                  placeholder="Email"
                  {...register("email")}
                  onChange={(e) => setValue("email", e.target.value)}
                />
                <p className="text-danger">
                  {errors && errors?.email?.message}
                </p>

                <div className="eye-container">
                  <label htmlFor="form7">Password</label>
                  <MDBInput
                    wrapperClass=""
                    id="form7"
                    type={showPassword ? "text" : "password"}
                    required
                    placeholder="Password"
                    {...register("password")}
                    onChange={(e) => setValue("username", e.target.value)}
                  />
                  <span
                    className="input-group-text eye-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>

                <p className="text-danger m-0">
                  {errors && errors?.password?.message}
                </p>
                <p className="forget-password m-0" style={{ fontSize: "14px" }}>
                  <Link to="/forgetPassword">Forget Password</Link>
                </p>

                <button className="w-100 btn btn-dark" size="md" type="submit">
                  Log in
                </button>

                <div className="text-center" style={{ marginTop: "10px" }}>
                  <Link to="/register" className="w-100">
                    Go To Register
                  </Link>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </form>
  );
}

export default Login;
