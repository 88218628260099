import React, { useContext, useEffect, useState } from "react";
import Billing from "./Billing ";
import PlaceOrder from "./PlaceOrder";
import "../checkOut/checkOut.scss";
import { useAuthHeaders } from "../../Components/Token";
import { getallCartApi } from "../../Components/services/getAllCart.service";
import { placeOrderApi } from "../../Components/services/placeOrder.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useNavigate } from "react-router-dom";
import { profileApi } from "../../Components/services/profile.service";
import userContext from "../../Components/UseContext/UseContext";

function CheckOut() {
  const [checkOut, setCheckOut] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [countery, setCountery] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const headers = useAuthHeaders();
  const navicate = useNavigate();

  const productContext = useContext(userContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getallCartApi({ headers });

        if (response && response.data) {
          // console.log("All add product successful", response.data);
          setCheckOut(response.data);
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } catch (error) {
        console.error("API call failed", error);
      }
    };

    fetchData();

    const profileApiData = async () => {
      try {
        const data = await profileApi(headers);
        setEmail(data.user.email);
        setPhone(data.user.mobile);
        setFirstName(data.user.name);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    profileApiData();
  }, []);

  const handlePlaeOrder = async (e) => {
    e.preventDefault();
    try {
      const response = await placeOrderApi({
        headers,
        firstName,
        lastName,
        address,
        countery,
        state,
        pinCode,
        phone,
        email,
        checkOut,
      });

      if (response && response.data) {
        console.log("response in placeOrderApi", response);

        Swal.fire({
          icon: "success",
          title: "Order place successfully",
          showConfirmButton: false,
          timer: 4000,
        });
        navicate("/");
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error("Registration failed", error.response);
      // alert("order failed. Please try again.");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Order failed. Please try again.",
      });
    }

    const profileApiData = async () => {
      try {
        const data = await profileApi(headers);
        console.log("data in profileApi", data.user.cart.length);
        localStorage.setItem("cartLength", data.user.cart.length)
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    profileApiData();
  };

  return (
    <>
      <div className="listProducts-banner">
        <h2>Checkout</h2>
      </div>
      <div className="container py-4">
        <form onSubmit={handlePlaeOrder} className="row billing">
          <Billing
            setFirstName={setFirstName}
            setLastName={setLastName}
            setAddress={setAddress}
            setCountery={setCountery}
            setState={setState}
            setPinCode={setPinCode}
            setPhone={setPhone}
            setEmail={setEmail}
            email={email}
            phone={phone}
            firstName={firstName}
          />
          <PlaceOrder checkOut={checkOut} handlePlaeOrder={handlePlaeOrder} />
        </form>
      </div>
    </>
  );
}

export default CheckOut;
